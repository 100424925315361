<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-card elevation="0" class="v-card-bottom-30">
      <v-card-title class="body-1">
        การเงิน <v-icon>mdi-chevron-right</v-icon><span>ประวัติการเดินเครดิต</span>
      </v-card-title>
    </v-card>

    <v-card>
      <v-card-title>
        <v-icon size="20" color="primary" class="mr-3 my-2">fa-donate</v-icon>
        <span v-if="balance > 0"
          >ยอดเงินคงเหลือ : <font class="ml-3" color="success"> {{ balance }}</font></span
        >
        <span v-else
          >ยอดเงินคงเหลือ : <font class="ml-3" color="red"> {{ balance }}</font></span
        >
        <span class="ml-2">บาท</span>
        <v-spacer />
        <vue-excel-xlsx
          v-if="data_balance_list.length > 0"
          :data="data_balance_list"
          :columns="excel_topupHistory_header"
          :file-name="'รายงานรายการ_balance'"
          :sheet-name="'รายงานรายการ_balance'"
          class="mr-2"
        >
          <v-btn color="success" outlined>
            <v-icon size="16" class="pr-1">fa-file-excel</v-icon> Export Excel</v-btn
          >
        </vue-excel-xlsx>
        <v-btn color="danger" dark to="/branch_topup">แจ้งชำระเงิน</v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2" lg="1" class="pb-3">
            <h5>วันที่ :</h5>
          </v-col>
          <v-col cols="12" md="2" lg="2" class="pb-3">
            <v-menu
              ref="search_menu_date_from"
              v-model="search_menu_date_from"
              :close-on-content-click="false"
              :return-value.sync="search_date_from"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="computed_search_date_from"
                  @change="(value) => (computed_search_date_from = value)"
                  autocomplete="off"
                  label="เริ่ม"
                  outlined
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  hide-details
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search_date_from"
                @input="
                  (search_menu_date_from = false),
                    $refs.search_menu_date_from.save(search_date_from)
                "
                no-title
                :allowed-dates="allowedDates"
                class="my-0"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="2" lg="2" class="pb-3">
            <v-menu
              ref="search_menu_date_to"
              v-model="search_menu_date_to"
              :close-on-content-click="false"
              :return-value.sync="search_date_to"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="computed_search_date_to"
                  @change="(value) => (computed_search_date_to = value)"
                  autocomplete="off"
                  label="สิ้นสุด"
                  outlined
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  hide-details
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search_date_to"
                @input="
                  (search_menu_date_to = false),
                    $refs.search_menu_date_to.save(search_date_to)
                "
                no-title
                :allowed-dates="allowedDates"
                class="my-0"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="2" lg="2">
            <v-btn
              color="primary"
              class="pa-2 mt-1"
              @click="getHistoryBalance(), (descriptionIndex = null)"
            >
              <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
              ค้นหา
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-5">
            <!-- แสดงจอ PC -->
            <div class="hidden-xs-only">
              <v-data-table
                :headers="headers"
                :items="data_balance_list"
                hide-default-footer
                :items-per-page="10000"
                class="elevation-0 packhai-border-table"
              >
                <template v-slot:item.createdDateTime="{ item }">
                  {{ set_format_date_time(item.createdDateTime).substring(0, 19) }}
                </template>

                <template v-slot:item.description="{ item }">
                  <v-row>
                    <v-col cols="12" align="left" v-if="item.income != null">
                      <span>{{ item.description }}</span>
                    </v-col>
                    <v-col
                      cols="12"
                      align="left"
                      style="padding-left: 50px !important"
                      v-else
                    >
                      <span>{{ item.description }}</span>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.income="{ item }">
                  <font v-if="item.income != null" color="success"
                    ><b>+ {{ formatMoney(item.income) }}</b></font
                  >
                </template>
                <template v-slot:item.expense="{ item }">
                  <font v-if="item.expense != null" color="red"
                    ><b>- {{ formatMoney(item.expense) }}</b></font
                  >
                </template>
                <template v-slot:item.balance="{ item }">
                  <font
                    ><b>{{ formatMoney(item.balance) }}</b></font
                  >
                </template>
                <template v-slot:item.seeMore="{ item }">
                  <span>
                    <v-btn
                      v-if="
                        item.hqInvoiceGroupID != null ||
                        item.hqInvoiceID != null ||
                        item.hqLogisticInvoiceID != null
                      "
                      @click="
                        seeMore_Invoice_List(
                          item.hqInvoiceGroupID,
                          item.hqInvoiceID,
                          item.hqLogisticInvoiceID
                        )
                      "
                      icon
                      ><v-icon color="primary">mdi-clipboard-text</v-icon></v-btn
                    >
                    <!-- <v-btn v-if="item.hqInvoiceGroupID != null" icon><v-icon >mdi-clipboard-text</v-icon></v-btn> -->
                  </span>
                </template>
                <!-- <template v-slot:item.Print="{ item }">
                    <v-btn v-if="item.IsPrint != null" icon @click="PrintPreview(item.InvoiceID)"><v-icon color="success">mdi-printer</v-icon></v-btn>
                </template> -->
              </v-data-table>
            </div>

            <!-- Mobile -->
            <div class="hidden-sm-and-up">
              <v-divider />
              <v-card
                elevation="0"
                class="my-1"
                v-for="(item, index) in data_balance_list"
                :key="index"
              >
                <v-row class="py-2">
                  <v-col class="pb-0" cols="4" align="left">
                    <b>{{
                      set_format_date_time(item.createdDateTime).substring(0, 10)
                    }}</b>
                  </v-col>
                  <v-col class="pb-0" cols="4" align="right">
                    <span v-show="item.income != null" style="color: green"
                      ><b>+ {{ item.income }}</b></span
                    >
                    <span v-show="item.expense != null" style="color: red"
                      ><b>- {{ item.expense }}</b></span
                    >
                  </v-col>
                  <v-col class="pb-0" cols="4" align="right">= {{ item.balance }}</v-col>
                </v-row>
                <v-row class="py-2">
                  <v-col class="pb-0" cols="6" align="left">{{
                    set_format_date_time(item.createdDateTime).substring(11, 16)
                  }}</v-col>
                  <v-col class="pt-0" cols="6" align="right">
                    <v-btn
                      icon
                      @click="showDescription(index, item.id)"
                      v-if="descriptionIndex != index"
                      ><v-icon>fa-angle-down</v-icon></v-btn
                    >
                    <v-btn icon @click="showDescription(index, item.id)" v-else=""
                      ><v-icon>fa-angle-up</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
                <v-row class="py-2" v-if="descriptionIndex == index">
                  <v-col class="pt-0" cols="9">
                    <span>{{ item.description }}</span>
                  </v-col>
                  <v-col class="pt-0" cols="3" align="center">
                    <span>
                      <v-btn
                        class="pb-5"
                        v-if="
                          item.hqInvoiceGroupID != null ||
                          item.hqInvoiceID != null ||
                          item.hqLogisticInvoiceID != null
                        "
                        @click="
                          seeMore_Invoice_List(
                            item.hqInvoiceGroupID,
                            item.hqInvoiceID,
                            item.hqLogisticInvoiceID
                          )
                        "
                        icon
                      >
                        <b
                          ><a href="#" style="color: #00bfff; text-decoration: none"
                            >รายละเอียด {{ ">>" }}
                          </a></b
                        >
                      </v-btn>
                      <!-- <v-btn class="pb-5" v-if="item.hqInvoiceGroupID != null" icon> <b>รายละเอียด {{'>>'}}</b>  </v-btn> -->
                    </span>
                  </v-col>
                </v-row>
                <v-divider />
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { header_token } from "@/website/token";
import Loading from "@/website/components/Loading";
import { branchService_dotnet } from "@/website/global";
import { AlertError } from "@/website/global_alert";
import {
  formatMoney,
  isNumberWNoDot,
  isNumberWithDot,
  isNumberWitMark,
  set_format_date_time,
} from "@/website/global_function";
export default {
  components: {
    Loading,
  },
  computed: {
    itemsPerPageBranch() {
      return this.dataBranch.length;
    },
    computed_search_date_from() {
      return this.formatDates(this.search_date_from);
    },
    computed_search_date_to() {
      return this.formatDates(this.search_date_to);
    },
  },
  watch: {
    filterSelect: async function () {
      await this.getHistoryBalance();
    },
  },
  data: () => ({
    page_loading: true,
    loading: false,

    search_menu_date_from: false,
    search_date_from: null,

    search_menu_date_to: false,
    search_date_to: null,

    sh_branch_id: null,
    sh_branch_list: [],

    balance: 0,
    branch_text: null,

    headers: [
      {
        text: "วันที่",
        align: "center",
        value: "createdDateTime",
        sortable: false,
        width: "10%",
      },
      {
        text: "รายละเอียด",
        align: "left",
        value: "description",
        sortable: false,
        width: "25%",
      },
      { text: "เข้า", align: "right", value: "income", sortable: false, width: "10%" },
      { text: "ออก", align: "right", value: "expense", sortable: false, width: "10%" },
      {
        text: "คงเหลือ",
        align: "right",
        value: "balance",
        sortable: false,
        width: "10%",
      },
      {
        text: "รายละเอียด",
        align: "center",
        value: "seeMore",
        sortable: false,
        width: "5%",
      },
      // { text: 'ปริ้น', align: 'center', value: 'Print', sortable: false, width: '5%'},
    ],
    data_balance_list: [],
    descriptionIndex: null,

    //excel
    excel_topupHistory_header: [
      {
        label: "วันที่",
        field: "createdDateTime",
        width: 20,
        dataFormat: set_format_date_time,
      },
      { label: "รายละเอียด", field: "description", width: 35 },
      { label: "เข้า", field: "income", width: 15 },
      { label: "ออก", field: "expense", width: 15 },
      { label: "คงเหลือ", field: "balance", width: 15 },
    ],

    // สาขา
  }),
  async created() {
    await this.getHistoryBalance();
    this.page_loading = false;
  },
  methods: {
    allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),

    formatDate(date) {
      if (date.length == 0) return null;

      if (date.length == 1) {
        const [year, month, day] = date[0].split("-");
        return `${day}-${month}-${year}`;
      } else {
        const [year_from, month_from, day_from] = date[0].split("-");
        const [year_to, month_to, day_to] = date[1].split("-");
        if (date[0] < date[1]) {
          return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`;
        } else {
          return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`;
        }
      }
    },

    formatDates(date) {
      if (date == null) {
        return null;
      } else {
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      }
    },

    showDescription(index, ID) {
      if (this.descriptionIndex == index) {
        this.descriptionIndex = null;
      } else {
        this.descriptionIndex = index;
      }
    },

    async getHistoryBalance() {
      this.loading = true;

      this.loading = true;

      let res1 = await axios.post(
        branchService_dotnet + "Balance/get-balance-statement-list",
        {
          branchID: localStorage.getItem("Branch_BranchID"),
          dateFrom: this.search_date_from,
          dateTo: this.search_date_to,
        },
        { headers: header_token }
      );

      this.balance = res1.data.balanceFormatted;
      this.data_balance_list = res1.data.statements;
      this.search_date_from = res1.data.dateFrom;
      this.search_date_to = res1.data.dateTo;
      this.loading = false;
    },

    seeMore_Invoice_List(hQInvoiceGroupID, hqInvoiceID, hqLogisticInvoiceID) {
      window.open(
        "/branch-view-detail-invoice?inv=" +
          hqInvoiceID +
          "&hqInvGroup=" +
          hQInvoiceGroupID +
          "&hqLogisticInv=" +
          hqLogisticInvoiceID,
        "_blank"
      );
    },

    formatMoney,
    isNumberWNoDot,
    isNumberWithDot,
    isNumberWitMark,
    set_format_date_time,
    AlertError,
  },
};
</script>
